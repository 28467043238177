import React from "react"
import { Row, Col } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFaucet,
  faBath,
  faBrush,
  faChair,
  faFan,
} from "@fortawesome/free-solid-svg-icons"

const Scrim = () => {
  return (
    <div className="scrim-wrapper py-10">
      <Row className="m-0">
        <Col md={2} />
        <Col md={8} className="m-0 p-0">
          <div className="scrim-card d-flex flex-column justify-content-center text-center p-5">
            <h4>We’ve got your home needs covered.</h4>
            <div className="d-flex justify-content-center flex-wrap mt-3">
              <div className="scrim-icon">
                <FontAwesomeIcon icon={faFaucet} />
              </div>
              <div className="scrim-icon">
                <FontAwesomeIcon icon={faBath} />
              </div>
              <div className="scrim-icon">
                <FontAwesomeIcon icon={faBrush} />
              </div>
              <div className="scrim-icon">
                <FontAwesomeIcon icon={faChair} />
              </div>
              <div className="scrim-icon">
                <FontAwesomeIcon icon={faFan} />
              </div>
            </div>
          </div>
        </Col>
        <Col md={2} />
      </Row>
    </div>
  )
}

export default Scrim
