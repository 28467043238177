import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowAltCircleRight,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons"
import Image from "../image"

const Services = () => {
  return (
    <Container id="services">
      <div className="py-10">
        <h1 className="text-center text-dark">Our Services</h1>
      </div>
      <Row>
        <Col
          md={6}
          className="d-flex align-items-center justify-content-center"
        >
          <div className="w-75">
            <Image src="cleaning-serice.png" />
          </div>
        </Col>
        <Col md={6}>
          <div className="mt-3 mt-md-0">
            <h5 className="font-weight-bold text-primary">CLEANING</h5>
            <p>Below are the most popular cleaning services offered</p>
            <ul className="p-0 list-unstyled services-list mt-3">
              <li>
                <FontAwesomeIcon icon={faArrowAltCircleRight} />
                <Link className="text-dark" to="/carpet-cleaning-services">
                  Carpet Cleaning
                </Link>
              </li>
              <li>
                <FontAwesomeIcon icon={faArrowAltCircleRight} />
                <Link className="text-dark" to="/tile-cleaning">
                  Tile Cleaning Systems
                </Link>
              </li>
              <li>
                <FontAwesomeIcon icon={faArrowAltCircleRight} />
                <Link className="text-dark" to="/gutters-services">
                  Gutter Cleaning Processing
                </Link>
              </li>
              <li>
                <FontAwesomeIcon icon={faArrowAltCircleRight} />
                <Link className="text-dark" to="/">
                  Home Cleaning
                </Link>
              </li>
              <li>
                <Link className="text-dark" to="/services">
                  <u>Explore more categories</u>
                </Link>{" "}
                <FontAwesomeIcon icon={faArrowRight} />
              </li>
            </ul>
          </div>
        </Col>
      </Row>
      <div className="v-spacer d-none d-md-block" />
      <Row className="mt-3">
        <Col md={6} className="order-2 order-md-1">
          <div className="mt-3 mt-md-0">
            <h5 className="font-weight-bold text-primary">REPAIRING</h5>
            <p>Find our hand picked quality repairing technician</p>
            <ul className="p-0 list-unstyled services-list mt-3">
              <li>
                <FontAwesomeIcon icon={faArrowAltCircleRight} />
                <Link
                  className="text-dark"
                  to="/window-installation-repair-services"
                >
                  Window Repairing
                </Link>{" "}
              </li>
              <li>
                <FontAwesomeIcon icon={faArrowAltCircleRight} />
                <Link className="text-dark" to="/shutters">
                  Shutter Repairing Systems
                </Link>{" "}
              </li>
              <li>
                <FontAwesomeIcon icon={faArrowAltCircleRight} />
                <Link className="text-dark" to="/doors-services">
                  Door Lock Repairing Processing
                </Link>{" "}
              </li>
              <li>
                <FontAwesomeIcon icon={faArrowAltCircleRight} />
                <Link
                  className="text-dark"
                  to="/carpet-installation-repair-services"
                >
                  Carpet Repairing
                </Link>{" "}
              </li>
              <li>
                <Link className="text-dark" to="/services">
                  <u>Explore more categories</u>
                </Link>{" "}
                <FontAwesomeIcon icon={faArrowRight} />
              </li>
            </ul>
          </div>
        </Col>
        <Col
          md={6}
          className="order-1 order-md-2 d-flex align-items-center justify-content-center"
        >
          <div className="w-75">
            <Image src="repairing-service-2.png" />
          </div>
        </Col>
      </Row>
      <div className="v-spacer d-none d-md-block" />
      <Row className="mt-3">
        <Col
          md={6}
          className="d-flex align-items-center justify-content-center"
        >
          <div className="w-50">
            <Image src="interior-decorator-services.png" />
          </div>
        </Col>
        <Col md={6}>
          <div className="mt-3 mt-md-0">
            <h5 className="font-weight-bold text-primary">HOME IMPROVEMENTS</h5>
            <p>Improve your home with our home improvement services</p>
            <ul className="p-0 list-unstyled services-list mt-3">
              <li>
                <FontAwesomeIcon icon={faArrowAltCircleRight} />
                <Link className="text-dark" to="/doors-services">
                  Doors Services
                </Link>
              </li>
              <li>
                <FontAwesomeIcon icon={faArrowAltCircleRight} />
                <Link className="text-dark" to="/fencing-services">
                  Fencing Services
                </Link>
              </li>
              <li>
                <FontAwesomeIcon icon={faArrowAltCircleRight} />
                <Link className="text-dark" to="/kitchen-remodel-services">
                  Kitchen Remodel Services
                </Link>
              </li>
              <li>
                <FontAwesomeIcon icon={faArrowAltCircleRight} />
                <Link className="text-dark" to="/powerwash-services">
                  Powerwash Services
                </Link>
              </li>
              <li>
                <u>Explore more categories</u>{" "}
                <FontAwesomeIcon icon={faArrowRight} />
              </li>
            </ul>
          </div>
        </Col>
      </Row>
      <div className="v-spacer d-none d-md-block" />
      <Row className="mt-3">
        <Col md={6} className="order-2 order-md-1">
          <div className="mt-3 mt-md-0">
            <h5 className="font-weight-bold text-primary">CONSTRUCTION</h5>
            <p>
              Choose a construction services and let us find the best match for
              you
            </p>
            <ul className="p-0 list-unstyled services-list mt-3">
              <li>
                <FontAwesomeIcon icon={faArrowAltCircleRight} />
                <Link className="text-dark" to="/additions">
                  Additions
                </Link>
              </li>
              <li>
                <FontAwesomeIcon icon={faArrowAltCircleRight} />
                <Link className="text-dark" to="/demolition-services">
                  Demolition Services
                </Link>
              </li>
              <li>
                <FontAwesomeIcon icon={faArrowAltCircleRight} />
                <Link className="text-dark" to="/home-builders">
                  Home Builders
                </Link>
              </li>
              <li>
                <FontAwesomeIcon icon={faArrowAltCircleRight} />
                <Link className="text-dark" to="/land-surveyor-services">
                  Land Surveyor Services
                </Link>
              </li>
              <li>
                <u>Explore more categories</u>{" "}
                <FontAwesomeIcon icon={faArrowRight} />
              </li>
            </ul>
          </div>
        </Col>
        <Col
          md={6}
          className="order-1 order-md-2 d-flex align-items-center justify-content-center"
        >
          <div className="w-50">
            <Image src="home-builders.png" />
          </div>
        </Col>
      </Row>
    </Container>
  )
}
export default Services
