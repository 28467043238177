import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/home/banner"
import Steps from "../components/home/steps"
import Services from "../components/home/services"
import ContactUs from "../components/home/contact-us"
import Scrim from "../components/home/scrim"

const IndexPage = props => (
  <Layout>
    <SEO title="Home" description="" />
    <Banner />
    <Steps />
    <Services />
    <ContactUs />
    <Scrim />
  </Layout>
)

export default IndexPage
