import React from "react"
import { Link } from "gatsby"
import { Row, Col, Button } from "reactstrap"
import Image from "../image"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const Banner = () => {
  return (
    <div className="banner">
      <Row className="m-0">
        <Col
          md={6}
          className="d-flex flex-column justify-content-center px-2 px-md-5 text-center text-light  text-md-left"
        >
          <h1 className="banner-title line-height-4 letter-spacing-2">
            Easy, fast and quality services for your house
          </h1>
          <p>
            We’re always looking for quality sellers. Our lead generation
            programs have helped thousands of clients close more business.
            Connect with pre-screened, in-market buyers today.
          </p>
          <div className="inline mt-3">
            <Link to="/services">
              <Button color="warning mr-2">Services</Button>
            </Link>
            <AnchorLink to="/#contact-us">
              <Button>Contact</Button>
            </AnchorLink>
          </div>
        </Col>
        <Col md={6} className="d-md-flex align-items-center d-none">
          <div className="banner-image-wrapper">
            <Image src="banner-4.png" />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Banner
